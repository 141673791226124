<template>
  <div>
    <div class="flex items-center justify-between mb-base">
      <h2>{{$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' ? $t('Inbound Orders') : $t('Return Orders')}}</h2>
      <vs-button v-if="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'" @click="openProductModal('New Product')" color="primary" icon-pack="feather" icon="icon-plus" size="large"></vs-button>
    </div>
    <orders-table type="inbound" :orderModal="orderModal" :confirmReturn="confirmReturn" @orderModal="orderModal = $event"/>
  </div>
</template>

<script>
import OrdersTable from './components/OrdersTable.vue'

export default {
  data () {
    return {
      orderModal: false,
      confirmReturn: true,
      order: {
        flags: [],
        customer: {
          address: {}
        }
      }
    }
  },
  methods: {
    openProductModal () {
      this.orderModal = true
    }
  },
  components: {
    OrdersTable
  }
}
</script>